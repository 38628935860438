import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "setting-webhooks",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#setting-webhooks",
        "aria-label": "setting webhooks permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setting webhooks`}</h2>
    <p>{`To obtain automatic transaction updates, or for notifications were the response is delayed, we support the following webhook events for each Medicare or DVA transaction:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Webhook`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Description`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`invoiceCancelled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Returned when the transaction is cancelled by the provider. Applicable for Medicare Patient Claims only.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`healthFundApprovedInvoice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For delayed decision transactions where the claim was approved. Applicable for Medicare Bulk Bill and DVA claims`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`healthFundRejectedInvoice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For delayed decision transactions where the claim was rejected and not paid. Applicable for Medicare Bulk Bill and DVA claims`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Use the webhooks attribute and provide:`}</p>
    <ol>
      <li parentName="ol">{`URL (This is usually transaction specific)`}</li>
      <li parentName="ol">{`Webhook event: one of the above listed events`}</li>
      <li parentName="ol">{`Method: POST/GET/PUT/DELETE`}</li>
      <li parentName="ol">{`Any required headers`}</li>
    </ol>
    <p><strong parentName="p">{`Webhook signing (optional)`}</strong></p>
    <p>{`To enhance the webhook security further, we support signing the payload with SHA-256 hmac signature for each of the POST webhook we sent. This will allow your server to ensure it`}{`'`}{`s only receiving requests coming from Tyro Health.`}</p>
    <p>{`To set up the webhook signing, please contact customer support to apply for a secret token that will be used for signature signing. Once the secret key is generated, it will be delivered either via keybase (preferred) or secure email.`}</p>
    <p>{`Once your secret token is set by Tyro Health , every POST request coming from`}</p>
    <p>{`Tyro Health will include two additional headers:`}</p>
    <ol>
      <li parentName="ol">{`X-Sender-Signature | A SHA-256 HMAC hash that`}{`'`}{`s generated based on X-Sender-Timestamp value and JSON stringified payload.`}</li>
      <li parentName="ol">{`X-Sender-Timestamp | Date in ISO date string format. It represents the date the request was sent. Also, It will be used for HMAC hash calculation.`}</li>
    </ol>
    <p>{`| ...
X-Sender-Signature=215d022a9e9c95fab7ca7c618d0d7b8d9e6dca1055d544b3d2421312a16a5651
X-Sender-Timestamp=`}{`"`}{`2021-01-13T04:23:50.659Z`}{`"`}{` |
| --- |`}</p>
    <p>{`To verify the hmac signature, you will need to compute your own SHA-256 HMAC signature and compare it with the signature provided in the header. So the code will be something like this:`}</p>
    <p>{`| const hmacSignature = Crypto.createHmac(`}{`"`}{`sha256`}{`"`}{`, SECRET`}{`_`}{`TOKEN)
.update(`}<inlineCode parentName="p">{`\${headers[&quot;X-Sender-Timestamp&quot;]}\${JSON.stringify(payload)}`}</inlineCode>{`)
.digest(`}{`"`}{`hex`}{`"`}{`);
return Crypto.timingSafeEqual(new Buffer.from(hmacSignature, `}{`"`}{`utf-8`}{`"`}{`), new Buffer.from(headers`}{`["`}{`X-Sender-Signature`}{`"]`}{`, `}{`"`}{`utf-8`}{`"`}{`)); |
| --- |`}</p>
    <p>{`Implementation between different languages will vary. Things to note:`}</p>
    <ul>
      <li parentName="ul">{`The HMAC function must be a SHA256 method`}</li>
      <li parentName="ul">{`The base for computing the hash is comprised of the timestamp in the header and stringified payload in the request`}</li>
      <li parentName="ul">{`Try to use timingSafeEqual equivalent function to compare the HMAC result to avoid timing attack on large string comparison`}</li>
    </ul>
    <p><strong parentName="p">{`Retries`}</strong></p>
    <p>{`We will attempt to retry failed webhooks if a timeout or `}{`'`}{`500`}{`'`}{` response is received from your endpoint. Retries are performed every 15 minutes for up to 24 hours or until a successful response is received.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      